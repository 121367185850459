<template>
<div class="kt-portlet">
    <div v-if="isSetupWizard && !isAdminLocked" class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <div v-if="!finalized" style="width: 72px">
                <span class="mt-3 kt-switch kt-switch--outline kt-switch--icon" :class="enableClassroom ? 'kt-switch--success' : 'kt-switch--danger'">
                    <label>
                        <input
                            v-model="enableClassroom"
                            type="checkbox"
                        >
                        <span />
                    </label>
                </span>
            </div>
            <h3 class="kt-portlet__head-title">
                Setup Google Classroom
                <small>
                    {{ enableClassroom ? 'Enabled' : 'Disabled' }}
                </small>
            </h3>
        </div>
    </div>

    <div class="kt-portlet__body">
        <b-overlay
            :variant="'transparent'"
            center
            opacity="1"
            blur="10px"
            :show="!enableClassroom"
        >
            <template #overlay>
                <p />
            </template>
            <div class="kt-pricing-1 kt-pricing-1--fixed p-3">
                <div class="kt-pricing-1__items row">
                    <div class="kt-pricing-1__item col-lg-4 d-flex flex-column">
                        <div class="kt-pricing-1__visual">
                            <div class="kt-pricing-1__hexagon1" />
                            <div class="kt-pricing-1__hexagon2" />
                            <span class="kt-pricing-1__icon kt-font-brand"><i style="margin-top: 36px;" class="fa flaticon-circle" /></span>
                        </div>
                        <span class="kt-pricing-1__price">Disabled</span>
                        <h2 class="kt-pricing-1__subtitle">
                            No Google Integration
                        </h2>
                        <span class="kt-pricing-1__description">
                            <span>My school does not intend to</span>
                            <span>integrate/sync with Google Classroom data</span>
                        </span>
                        <div class="kt-pricing-1__btn mt-auto">
                            <button
                                type="button"
                                class="btn btn-wide btn-bold btn-upper"
                                :class="[
                                    enableClassroomValue === 'Disabled' ? 'btn-primary' : 'btn-outline-primary'
                                ]"
                                @click.stop.prevent="saveGoogleSettings('Disabled')"
                            >
                                {{ enableClassroomValue === 'Disabled' ? 'Active' : 'Select' }}
                            </button>
                        </div>
                    </div>
                    <div class="kt-pricing-1__item col-lg-4 d-flex flex-column">
                        <span class="kt-badge kt-badge--success kt-badge--inline kt-font-boldest"><i class="fa fa-star mr-2" />Most Popular</span>
                        <div class="kt-pricing-1__visual">
                            <div class="kt-pricing-1__hexagon1" />
                            <div class="kt-pricing-1__hexagon2" />
                            <span class="kt-pricing-1__icon kt-font-brand"><i style="font-size: 7rem" class="fa flaticon-letter-g" /></span>
                        </div>
                        <span class="kt-pricing-1__price">
                            School Managed
                        </span>
                        <h2 class="kt-pricing-1__subtitle">
                            Self-Managed Google Workspace
                        </h2>
                        <span class="kt-pricing-1__description" style="padding-bottom: 14px;">
                            <span>My school intends to use a self-managed</span>
                            <span>Google Workspace to sync with Google Classroom</span>
                            <span class="kt-font-bolder mt-3">To get started if you don't have one visit <a href="https://edu.google.com/intl/ALL_us/workspace-for-education/editions/overview/" target="_blank">here</a></span>
                        </span>
                        <div class="kt-pricing-1__btn mt-auto">
                            <button
                                type="button"
                                class="btn btn-wide btn-bold btn-upper"
                                :class="[
                                    enableClassroomValue === 'School Managed' ? 'btn-primary' : 'btn-outline-primary'
                                ]"
                                @click.stop.prevent="saveGoogleSettings('School Managed')"
                            >
                                {{ enableClassroomValue === 'School Managed' ? 'Active' : 'Select' }}
                            </button>
                        </div>
                    </div>
                    <div class="kt-pricing-1__item col-lg-4 d-flex flex-column">
                        <div class="kt-pricing-1__visual">
                            <div class="kt-pricing-1__hexagon1" />
                            <div class="kt-pricing-1__hexagon2" />
                            <span class="kt-pricing-1__icon kt-font-brand"><i style="margin-top: 36px;" class="la la-building" /></span>
                        </div>
                        <span class="kt-pricing-1__price">DOE Central</span>
                        <h2 class="kt-pricing-1__subtitle">
                            DOE Central Google Domain
                        </h2>
                        <span class="kt-pricing-1__description">
                            <span>My school uses the DOE Central Domain</span>
                            <span>and intends to integrate/sync with Google Classroom data</span>
                            <span class="kt-font-bolder mt-3">To view how teachers import data from Google</span>
                            <span class="kt-font-bolder">visit <a href="#" target="_blank">here</a></span>
                        </span>
                        <div class="kt-pricing-1__btn mt-auto">
                            <button
                                type="button"
                                class="btn btn-wide btn-bold btn-upper"
                                :class="[
                                    enableClassroomValue === 'District Managed' ? 'btn-primary' : 'btn-outline-primary'
                                ]"
                                @click.stop.prevent="saveGoogleSettings('District Managed')"
                            >
                                {{ enableClassroomValue === 'District Managed' ? 'Active' : 'Select' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import userMixins from '../../store/mixins/userMixins';
import Types from '../../store/Types';

export default {
    name: 'SetupGoogleDomain',
    mixins: [ userMixins],
    data() {
        return {
            saving: false,
            linkError: false,
            showDisconnectNotice: false,
            host: window.location.href.indexOf('localhost') > -1 ? 'http://localhost:3000' : '',
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            school: (state) => state.user.school,
            googleFriendlyName: (state) => `${state.user.firstName} ${state.user.lastName}`,
            googleEmail: (state) => state.user.googleEmail,
            unlinkedGoogleEmail: (state) => state.user.unlinkedGoogleEmail,
            googleDomain(state) {
                return state.user.googleEmail ? state.user.googleEmail.split('@')[1] : '';
            },
            googleUser(state) {
                return state.user.googleEmail ? state.user.googleEmail.split('@')[0] : '';
            },
        }),
        finalized() {
            return this.$store.state.user.school.finalized;
        },
        isAdminLocked() {
            const school = this.$store.state.database.schools
                .find((s) => s.schoolId == this.$store.state.user.school.schoolId);
            const { schoolYear } = this.$store.state.user.school;
            const { isSuperAdmin } = this.$store.state.user;
            const { subscriptions } = school;
            const subscription = subscriptions.find((s) => s.schoolYear == schoolYear );
            let isAdminLocked = false;
            if (subscription) {
                isAdminLocked = subscription.adminLocked && !isSuperAdmin;
            }
            return isAdminLocked;
        },
        isSetupWizard() {
            return this.$route.name == "SetupWizardRoute";
        },
        redirectUri() {
            const { pathname } = window.location;
            return `/auth/google/redirect?callbackUrl=${pathname}`;
        },
        step() {
            return this.$store.state.wizard.steps.find((r) => r.routeName == 'setup-google-classroom');
        },
        enableClassroomValue: {
            get() {
                return this.$store.state.wizard.bypassWizardGoogleDomain;
            },
            set(googleSetting) {
                if (!this.isSetupWizard) return;

                this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, {
                    routeName: 'setup-google-classroom',
                    value: googleSetting,
                });

                this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);
            },
        },
        enableClassroom: {
            get() {
                const { bypassWizardGoogleDomain} = this.$store.state.wizard;
                if (bypassWizardGoogleDomain === '' || bypassWizardGoogleDomain === 'Disabled') {
                    return false;
                }
                return true;
            },
            set(enableClassroom) {
                if (!this.isSetupWizard) return;
                const googleSetting = enableClassroom ? 'School Managed' : 'Disabled';

                this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, {
                    routeName: 'setup-google-classroom',
                    value: googleSetting,
                });

                this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);
            },
        }
    },
    mounted() {
        const v = this;
        const urlParams = new URLSearchParams(window.location.search);
        const search = Object.fromEntries(urlParams);

        if (Object.keys(search).includes('error')) {
            const errorValue = search.error;
            if (errorValue === 'googleEmailMismatch') {
                v.linkError = true;
            }
        }
    },
    methods: {
        redirect() {
            window.location.href = '/auth/google/redirect';
        },
        saveGoogleSettings(googleSetting) {
            this.enableClassroomValue = googleSetting;

        },

    },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/pages/pricing/pricing-1.scss";

.kt-pricing-1__price {
    font-size: 2.14rem !important;
    margin-bottom: 0.571rem !important;
    margin-top: 140px !important;
    color: kt-base-color(label, 3) !important;
}
</style>
