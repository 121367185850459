var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-portlet" }, [
    _vm.isSetupWizard && !_vm.isAdminLocked
      ? _c("div", { staticClass: "kt-portlet__head" }, [
          _c("div", { staticClass: "kt-portlet__head-label" }, [
            !_vm.finalized
              ? _c("div", { staticStyle: { width: "72px" } }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "mt-3 kt-switch kt-switch--outline kt-switch--icon",
                      class: _vm.enableClassroom
                        ? "kt-switch--success"
                        : "kt-switch--danger",
                    },
                    [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.enableClassroom,
                              expression: "enableClassroom",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.enableClassroom)
                              ? _vm._i(_vm.enableClassroom, null) > -1
                              : _vm.enableClassroom,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.enableClassroom,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.enableClassroom = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.enableClassroom = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.enableClassroom = $$c
                              }
                            },
                          },
                        }),
                        _c("span"),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("h3", { staticClass: "kt-portlet__head-title" }, [
              _vm._v(" Setup Google Classroom "),
              _c("small", [
                _vm._v(
                  " " +
                    _vm._s(_vm.enableClassroom ? "Enabled" : "Disabled") +
                    " "
                ),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "kt-portlet__body" },
      [
        _c(
          "b-overlay",
          {
            attrs: {
              variant: "transparent",
              center: "",
              opacity: "1",
              blur: "10px",
              show: !_vm.enableClassroom,
            },
            scopedSlots: _vm._u([
              {
                key: "overlay",
                fn: function () {
                  return [_c("p")]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c("div", { staticClass: "kt-pricing-1 kt-pricing-1--fixed p-3" }, [
              _c("div", { staticClass: "kt-pricing-1__items row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "kt-pricing-1__item col-lg-4 d-flex flex-column",
                  },
                  [
                    _c("div", { staticClass: "kt-pricing-1__visual" }, [
                      _c("div", { staticClass: "kt-pricing-1__hexagon1" }),
                      _c("div", { staticClass: "kt-pricing-1__hexagon2" }),
                      _c(
                        "span",
                        { staticClass: "kt-pricing-1__icon kt-font-brand" },
                        [
                          _c("i", {
                            staticClass: "fa flaticon-circle",
                            staticStyle: { "margin-top": "36px" },
                          }),
                        ]
                      ),
                    ]),
                    _c("span", { staticClass: "kt-pricing-1__price" }, [
                      _vm._v("Disabled"),
                    ]),
                    _c("h2", { staticClass: "kt-pricing-1__subtitle" }, [
                      _vm._v(" No Google Integration "),
                    ]),
                    _c("span", { staticClass: "kt-pricing-1__description" }, [
                      _c("span", [_vm._v("My school does not intend to")]),
                      _c("span", [
                        _vm._v("integrate/sync with Google Classroom data"),
                      ]),
                    ]),
                    _c("div", { staticClass: "kt-pricing-1__btn mt-auto" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-wide btn-bold btn-upper",
                          class: [
                            _vm.enableClassroomValue === "Disabled"
                              ? "btn-primary"
                              : "btn-outline-primary",
                          ],
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.saveGoogleSettings("Disabled")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.enableClassroomValue === "Disabled"
                                  ? "Active"
                                  : "Select"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "kt-pricing-1__item col-lg-4 d-flex flex-column",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "kt-badge kt-badge--success kt-badge--inline kt-font-boldest",
                      },
                      [
                        _c("i", { staticClass: "fa fa-star mr-2" }),
                        _vm._v("Most Popular"),
                      ]
                    ),
                    _c("div", { staticClass: "kt-pricing-1__visual" }, [
                      _c("div", { staticClass: "kt-pricing-1__hexagon1" }),
                      _c("div", { staticClass: "kt-pricing-1__hexagon2" }),
                      _c(
                        "span",
                        { staticClass: "kt-pricing-1__icon kt-font-brand" },
                        [
                          _c("i", {
                            staticClass: "fa flaticon-letter-g",
                            staticStyle: { "font-size": "7rem" },
                          }),
                        ]
                      ),
                    ]),
                    _c("span", { staticClass: "kt-pricing-1__price" }, [
                      _vm._v(" School Managed "),
                    ]),
                    _c("h2", { staticClass: "kt-pricing-1__subtitle" }, [
                      _vm._v(" Self-Managed Google Workspace "),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "kt-pricing-1__description",
                        staticStyle: { "padding-bottom": "14px" },
                      },
                      [
                        _c("span", [
                          _vm._v("My school intends to use a self-managed"),
                        ]),
                        _c("span", [
                          _vm._v(
                            "Google Workspace to sync with Google Classroom"
                          ),
                        ]),
                        _c("span", { staticClass: "kt-font-bolder mt-3" }, [
                          _vm._v("To get started if you don't have one visit "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://edu.google.com/intl/ALL_us/workspace-for-education/editions/overview/",
                                target: "_blank",
                              },
                            },
                            [_vm._v("here")]
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "kt-pricing-1__btn mt-auto" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-wide btn-bold btn-upper",
                          class: [
                            _vm.enableClassroomValue === "School Managed"
                              ? "btn-primary"
                              : "btn-outline-primary",
                          ],
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.saveGoogleSettings("School Managed")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.enableClassroomValue === "School Managed"
                                  ? "Active"
                                  : "Select"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "kt-pricing-1__item col-lg-4 d-flex flex-column",
                  },
                  [
                    _c("div", { staticClass: "kt-pricing-1__visual" }, [
                      _c("div", { staticClass: "kt-pricing-1__hexagon1" }),
                      _c("div", { staticClass: "kt-pricing-1__hexagon2" }),
                      _c(
                        "span",
                        { staticClass: "kt-pricing-1__icon kt-font-brand" },
                        [
                          _c("i", {
                            staticClass: "la la-building",
                            staticStyle: { "margin-top": "36px" },
                          }),
                        ]
                      ),
                    ]),
                    _c("span", { staticClass: "kt-pricing-1__price" }, [
                      _vm._v("DOE Central"),
                    ]),
                    _c("h2", { staticClass: "kt-pricing-1__subtitle" }, [
                      _vm._v(" DOE Central Google Domain "),
                    ]),
                    _c("span", { staticClass: "kt-pricing-1__description" }, [
                      _c("span", [
                        _vm._v("My school uses the DOE Central Domain"),
                      ]),
                      _c("span", [
                        _vm._v(
                          "and intends to integrate/sync with Google Classroom data"
                        ),
                      ]),
                      _c("span", { staticClass: "kt-font-bolder mt-3" }, [
                        _vm._v("To view how teachers import data from Google"),
                      ]),
                      _c("span", { staticClass: "kt-font-bolder" }, [
                        _vm._v("visit "),
                        _c("a", { attrs: { href: "#", target: "_blank" } }, [
                          _vm._v("here"),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "kt-pricing-1__btn mt-auto" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-wide btn-bold btn-upper",
                          class: [
                            _vm.enableClassroomValue === "District Managed"
                              ? "btn-primary"
                              : "btn-outline-primary",
                          ],
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.saveGoogleSettings("District Managed")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.enableClassroomValue === "District Managed"
                                  ? "Active"
                                  : "Select"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }